import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        sources: [],
        formats: [],
        sft_profiles: {},
        exportHistory: [],
    }
}

const getters = {
    getSources: state => state.sources,
    getFormats: state => state.formats,
    getSftpProfiles: state => state.sft_profiles,
    getExportHistory: state => state.exportHistory,
}

const actions = {
    doExport({commit, _}, params) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/export',
                {
                    ...{
                        type: params.type,
                        tableFormat: params.tableFormat,
                        filter: params.filter,
                        per_page: params.perPage,
                        page: params.currentPage,
                        'export': params.export,
                    },
                    ...params.additionalOptions
                },
                {'skip_loading': true}
            )
                .then(response => {
                    resolve(response)
                }, () => {
                    reject()
                })
        })
    },
    fetchOptions({commit, _}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/export/options', {'skip_loading': true})
                .then(response => {
                    commit('setOptions', response.data)
                    resolve(response)
                })
        })
    },
    fetchExport({commit, _}, params) {
        return commonTableFetch(commit, '/export/history', 'setExportTable', params)
    },
    deleteExportHistory({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/export/history/' + id).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setOptions(state, options) {
        state.sources = options.sources
        state.formats = options.formats
        state.sft_profiles = options.sft_profiles
    },
    setExportTable(state, data) {
        state.exportHistory = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}