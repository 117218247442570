import {commonStoreSave, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        offeringsTable: [],
        customersTable: [],
        notificationsTable: [],
        contractsTable: [],
        todosTable: [],
    }
}

const getters = {
    getOfferingsTable: state => state.offeringsTable,
    getCustomersTable: state => state.customersTable,
    getContractsTable: state => state.contractsTable,
    getTodosTable: state => state.todosTable,
    getNotificationsTable: state => state.notificationsTable,
}

const actions = {
    fetchOfferings({commit, _}, params) {
        return commonTableFetch(commit, '/crm', 'setOfferingsTable', params)
    },
    fetchContracts({commit, _}, params) {
        return commonTableFetch(commit, '/crm/contracts', 'setContractsTable', params)
    },
    fetchTodos({commit, _}, params) {
        return commonTableFetch(commit, '/crm/todos', 'setTodosTable', params)
    },
    getCustomerList({commit}, form) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/crm/todos/customer-list', form)
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    fetchCustomers({commit, _}, params) {
        return commonTableFetch(commit, '/crm/customers', 'setCustomersTable', params)
    },
    fetchNotifications({commit, _}, params) {
        return commonTableFetch(commit, '/crm/notifications', 'setNotificationsTable', params)
    },
    closeTodos({commit}, data) {
        return commonStoreSave('/crm/close-todos', data)
    },
    deleteTodo({dispatch}, {id}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/crm/todos/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    deleteEvent({dispatch}, {id}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/events/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    deleteTodos({dispatch}, ids) {
        return new Promise((resolve) => {
            let queryString = ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');

            window.axios.delete(window.apiUrl + '/crm/todos?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },

}

const mutations = {
    setOfferingsTable(state, data) {
        state.offeringsTable = data
    },
    setTodosTable(state, data) {
        state.todosTable = data
    },
    setContractsTable(state, data) {
        state.contractsTable = data
    },
    setCustomersTable(state, data) {
        state.customersTable = data
    },
    setNotificationsTable(state, data) {
        state.notificationsTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}