<template>
    <th :class="'table_th_'+column.filter.type">
        <div v-if="column.key=='selected'">
            <b-row>
                <b-col cols="10">
                    <zw-select-group
                        v-model="selectSource"
                        :options="sourceOptions"
                        name="selectedSource"
                        :disableLabel="true"
                        @change="sourceSelected"
                        size="s"
                    ></zw-select-group>
                </b-col>
            </b-row>
        </div>
        <div v-if="column.filter">
            <template v-if="column.filter.type=='text'">
                <b-form-input :value="value[column.key]"
                              v-if="!Array.isArray(value[column.key])"
                              @change="(val)=>{filterFieldChanged(val, column)}"
                              @keyup.enter="startFiltering"
                              autocomplete="off"
                ></b-form-input>
                <div v-else>{{value[column.key].join(',')}}</div>
            </template>

            <div v-if="column.filter.type=='date'">
                <b-form inline>
                    <date-picker :value="getDateFromValue(column)"
                                 :config="config"
                                 @input="(val)=>{dateFilterChanged(val, column)}"
                                 style="max-width: 80px"
                                 class="mr-1"
                    ></date-picker>
                    -
                    <date-picker :value="getDateFromValue(column,'till')"
                                 :config="config"
                                 @input="(val)=>{dateFilterChanged(val, column,'till')}"
                                 style="max-width: 80px"
                                 class="ml-1"
                    ></date-picker>
                </b-form>
            </div>

            <b-form-select v-if="column.filter.type=='select'"
                           :value="value[column.key]"
                           :options="formatOptions(column.filter.options)"
                           text-field="name"
                           value-field="id"
                           :select-size="1"
                           @input="(value)=>{filterFieldChanged(value, column);startFiltering()}"
            ></b-form-select>

            <multiselect v-if="column.filter.type=='multiselect'"
                         :value="transformToArray(value[column.key])"
                         @input="(value)=>{filterFieldChanged(value, column);preStartFiltering(value,column)}"
                         :options="Object.keys(column.filter.options).map(cat=>parseInt(cat))"
                         :custom-label="opt => column.filter.options[opt]"
                         :searchable="true"
                         :multiple="true"
                         placeholder=""
                         :tagPosition="'bottom'"
                         select-label="">

                <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single"
                          style="font-weight: normal; font-size: 14px;"
                          v-if="values.length > 1"
                          v-show="!isOpen">
                        {{ values.length }} {{ $t('common.label.options_selected') }}
                    </span>
                </template>
            </multiselect>

            <multiselect v-if="column.filter.type=='multiselect-checkbox'"
                         :value="transformToArray(value[column.key])"
                         @close="(value)=>{sendTempValues(column)}"
                         :options="Object.keys(column.filter.options).map(cat=>parseInt(cat))"
                         :custom-label="opt => column.filter.options[opt]"
                         :searchable="true"
                         :close-on-select="false"
                         :multiple="true"
                         placeholder=""
                         :tagPosition="'bottom'"
                         :showLabels="false"
                         select-label="">

                <template class="checkbox-label"
                          slot="option"
                          slot-scope="props"
                          @click.self="()=>{}"
                >
                    <input type="checkbox"
                           :checked="value[column.key] && value[column.key].split(',').includes(props.option.toString())"
                           @click="fillTempValues($event,{'val':props.option,'key':column.key})"
                           @focus.prevent/>
                    {{ column.filter.options[props.option] }}
                </template>
                <template slot="tag" slot-scope="{ option, remove }">
                    <span :class="getClassTag(option)">
                        <span>{{ column.filter.options[option] }}</span>
                        <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon"
                           @click="removeCheckBoxOption(column)"></i>
                    </span>
                </template>
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single"
                          style="font-weight: normal; font-size: 14px;"
                          v-if="values.length > 1"
                          v-show="!isOpen">{{ values.length }} {{ $t('common.label.options_selected') }}
                    </span>
                </template>
                <template slot="beforeList">
                    <li style="position: -webkit-sticky;position: sticky;top: 0;background-color: #f2f2f2;">
                        <b-form-checkbox
                            class="ml-2"
                            :checked="isAll(column)"
                            value="1"
                            unchecked-value="0"
                            @change="(val)=>{setAll(val, column)}"
                        >
                            All
                        </b-form-checkbox>
                    </li>
                </template>
                <template slot="afterList">
                    <li style="position: -webkit-sticky;position: sticky;bottom: 0;background-color: #f2f2f2;">
                        <b-form-checkbox
                            class="ml-2"
                            :checked="isNone(column.key)"
                            value="1"
                            unchecked-value="0"
                            @change="(val)=>{setIsNone(val, column)}"
                        >
                            Empty
                        </b-form-checkbox>
                    </li>
                    <li style="position: -webkit-sticky;position: sticky;bottom: 0;background-color: #f2f2f2;">
                        <b-button @click="startFiltering" block>Go</b-button>
                    </li>
                    <!--                    <li style="position: -webkit-sticky;position: sticky;bottom: 0;background-color: #f2f2f2;">-->
                    <!--                        <b-form-checkbox-->
                    <!--                            class="ml-2"-->
                    <!--                            :checked="isEmpty(column.key)"-->
                    <!--                            value="1"-->
                    <!--                            unchecked-value="0"-->
                    <!--                            @change="(val)=>{setIsEmpty(val, column)}"-->
                    <!--                        >-->
                    <!--                            None-->
                    <!--                        </b-form-checkbox>-->
                    <!--                    </li>-->
                </template>
            </multiselect>

            <multiselect v-if=" column.filter.type=='multiselect-values'"
                         :value="transformToArray(value[column.key], false)"
                         @input="(value)=>{filterFieldChanged(value, column);preStartFiltering(value,column, false)}"
                         :options="Object.keys(column.filter.options)"
                         :custom-label="opt => column.filter.options[opt]"
                         :searchable="true"
                         :multiple="true"
                         placeholder=""
                         :tagPosition="'bottom'"
                         select-label=""
            >
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single"
                          style="font-weight: normal; font-size: 14px;"
                          v-if="values.length > 1"
                          v-show="!isOpen">{{ values.length }} {{ $t('common.label.options_selected') }}
                    </span>
                </template>
            </multiselect>

            <multiselect v-if="column.filter.type=='multiselect-group'"
                         :value="transformToArray(value[column.key], false)"
                         @input="(value)=>{filterFieldChanged(value, column);startFiltering()}"
                         :options="column.filter.options"
                         :searchable="true"
                         :multiple="true"
                         placeholder=""
                         group-values="group-values"
                         group-label="group-label"
                         :group-select="true"
                         label="name"
                         select-label=""
            >
                <template slot="tag" slot-scope="{ option, remove }"><span class="multiselect__tag">
                    <span>{{ cleanOption(option) }}</span>
                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
                </span>
                </template>
                <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="value[column.key] && value[column.key].length"
                         @mousedown.prevent.stop="filterFieldChanged(null, column)">
                    </div>
                </template>
            </multiselect>
        </div>
        <div v-if="column.key=='total' || column.key=='actions'">
            <b-button :disabled="!Object.keys(value).length" @click="resetFilter"
                      variant="warning">{{ $t('common.button.reset') }}
            </b-button>
        </div>
    </th>
</template>

<script>
import moment from 'moment'

export default {
    name: 'table-filter',
    props: [
        'column',
        'attributes',
        'tableOptions',
        'value',
        'sourceOptions',
        'tableSource',
    ],
    data() {
        return {
            all: false,
            filterSelect: null,
            selectedSource: 'selected',
            source: null,
            config: {
                format: 'DD.MM.YYYY',
                useCurrent: false,
                showClear: true,
                locale: 'de',
                keyBinds: {
                    right: null,
                    left: null,
                    delete: null,
                }
            },
            tmpValues: {},
        }
    },
    created() {
    },
    methods: {
        getClassTag(option) {
            let tagClass = 'multiselect__tag'
            if (option == -1) {
                tagClass += ' d-none'
            }
            return tagClass
        },
        isAll(column) {
            let value = 0;
            if (typeof this.tableSource.filter[column.key] != "undefined"
                && this.tableSource.filter[column.key].split(",").length == column.filter.options.length) {
                value = 1;
            }
            return value;
        },
        setAll(val, column) {
            if (val == 1) {
                this.filterFieldChanged(Object.keys(column.filter.options), column)
                this.preStartFiltering(Object.keys(column.filter.options), column)
            } else {
                this.filterFieldChanged([], column)
                this.preStartFiltering([], column)
            }
        },
        isNone(key) {
            let value = 0;
            if (typeof this.tableSource.filter[key] != "undefined") {
                let ids = this.tableSource.filter[key].split(",").map(Number);
                var index = ids.indexOf(-1);
                if (index !== -1) {
                    value = 1;
                }
            }
            return value;
        },
        isEmpty(key) {
            let value = 0;
            if (typeof this.tableSource.filter[key] != "undefined") {
                let ids = this.tableSource.filter[key].split(",").map(Number);
                var index = ids.indexOf(-2);
                if (index !== -2) {
                    value = 1;
                }
            }
            return value;
        },
        setIsNone(val, column) {
            let ids = []
            if (typeof this.value[column.key] != "undefined") {
                ids = this.value[column.key].split(",").map(Number);
            }
            if (val == 1) {
                ids.push(-1)
            } else {
                var index = ids.indexOf(-1);
                if (index !== -1) {
                    ids.splice(index, 1);
                }
            }
            this.$nextTick(() => {
                this.filterFieldChanged(ids.join(","), column)
                this.preStartFiltering(ids.join(","), column)
            })
        },
        setIsEmpty(val, column) {
            let ids = []
            if (typeof this.value[column.key] != "undefined") {
                ids = this.value[column.key].split(",").map(Number);
            }
            if (val == 1) {
                ids.push(-2)
            } else {
                var index = ids.indexOf(-2);
                if (index !== -2) {
                    ids.splice(index, 1);
                }
            }
            this.$nextTick(() => {
                this.filterFieldChanged(ids.join(","), column)
                this.preStartFiltering(ids.join(","), column)
            })
        },
        fillTempValues(event, clickData) {
            const isChecked = event.target.checked;
            if (typeof this.tmpValues[clickData.key] == "undefined") {
                if (typeof this.value[clickData.key] != "undefined") {
                    this.tmpValues[clickData.key] = this.value[clickData.key].split(',')
                    this.tmpValues[clickData.key] = this.value[clickData.key].split(',').map(number => number.toString());
                } else {
                    this.tmpValues[clickData.key] = []
                }
            }

            if (isChecked) {
                this.tmpValues[clickData.key].push(clickData.val.toString());
            } else {
                this.tmpValues[clickData.key] = this.tmpValues[clickData.key].filter(function (e) {
                    return e !== clickData.val.toString()
                })
            }
        },
        sendTempValues(column) {
            this.$nextTick(() => {
                if (typeof this.tmpValues[column.key] != "undefined") {
                    let values = Object.values(this.tmpValues[column.key]);
                    this.filterFieldChanged(values, column)
                    this.preStartFiltering(values, column)
                    this.tmpValues[column.key] = []
                }
            })
        },
        removeCheckBoxOption(column) {
            this.filterFieldChanged([], column)
            this.preStartFiltering([], column)
        },
        customGroupLabel({id, name}) {
            if (typeof id != "undefined") {
                if (id.includes('__')) {
                    let splits = id.split('__');
                    return splits[1];
                } else {
                    return id;
                }
            }
        },
        formatOptions(options) {
            if (typeof options == "object") {
                let newOptions = {};
                for (const [key, value] of Object.entries(options)) {
                    newOptions[key] = value
                }
                return newOptions
            } else {
                return options
            }
        },
        cleanOption(option) {
            if (option.includes('__')) {
                let splits = option.split('__');
                return splits[1];
            } else {
                return option;
            }
        },
        resetFilter() {
            Object.keys(this.value).forEach(key => {
                this.$delete(this.value, key)
            })
            this.$emit('input', {})
        },
        filterFieldChanged(value, column) {
            if (this.tableSource.saveFilters) {
                this.$set(this.value, 'filterChanged', true)
            }
            this.$delete(this.value, '_filter_id')
            if (value && value.length) {
                if (Array.isArray(value)) {
                    function onlyUnique(value, index, self) {
                        return self.indexOf(value) === index;
                    }

                    let newValues = value.map(option => option.id || option).join(',')
                    let uniqueValues = newValues.split(',').filter(onlyUnique).join(',');

                    this.$set(this.value, column.key, uniqueValues)
                } else {
                    this.$set(this.value, column.key, value)
                }
            } else {
                this.$delete(this.value, column.key)
            }
        },
        preStartFiltering(val, column, isInt = true) {
            if (!val.includes(-1) && (val.includes(0) || (!isInt && val.includes('0')))) {
                let newValue = []
                Object.keys(column.filter.options).forEach((value) => {
                    if (isInt) {
                        value = parseInt(value)
                    }
                    if ((value && isInt) || (!isInt && value != '0')) {
                        newValue.push(value)
                    }
                })
                this.$set(this.value, column.key, newValue.join(','))
            }

            this.tableSource.changeBaseFilter(this.value)
        },
        startFiltering(val, column) {
            this.tableSource.changeBaseFilter(this.value)
        },
        dateFilterChanged(value, column, position = 'from') {
            let from = null
            let till = null
            let currentValue = (typeof (this.value[column.key]) != "undefined") ? this.value[column.key] : 'null;null'

            const dates = currentValue.split(';')
            from = dates[0]
            till = dates[1]

            if (value) {
                if (position == 'from') {
                    from = value
                } else {
                    till = value
                }
                this.$set(this.value, column.key, from + ';' + till)
            } else {
                this.$delete(this.value, column.key)
            }
            this.startFiltering()
        },
        getDateFromValue(column, position = 'from') {
            if (this.value[column.key]) {
                let currentValue = this.value[column.key]
                const dates = currentValue.split(';')
                let from = dates[0]
                let till = dates[1]
                if (position == 'from') {
                    return from
                } else {
                    return till
                }
            } else {
                return null
            }
        },
        transformToArray(value, toInt = true) {
            if (typeof value == "string") {
                value = value.split(",");
                value = value.filter(function (element) {
                    return Boolean(element) || element === 0;
                });
                value = value.map(function (x) {
                    if (toInt) {
                        return parseInt(x);
                    } else {
                        return x;
                    }

                });
            }
            return value;
        },
        sourceSelected(val) {
            if (val == 'current_page') {
                this.$emit('select', true);
            }
            if (val == 'none') {
                this.$emit('select', false);
            }
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        selectAll: {
            get() {
                return this.attributes.selected.length == this.tableOptions.perPage ? true : false
            },
            set(val) {
                this.$emit('select', val);
            }
        },
        selectSource: {
            get() {
                return this.attributes.selectedSource
            },
            set(val) {
                this.$emit('selectSource', val);
            }
        },
    },
    watch: {
        // selectSource: function (val) {
        //     if (val == 'current_page') {
        //         this.$emit('select', true);
        //     }
        //     if (val == 'none') {
        //         this.$emit('select', false);
        //     }
        // },
    }
}
</script>