import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        incomingInvoicesTable: {},
        liabilitiesTable: {},
        xInvoicesTable: {},
        incomingInvoice: {},
    }
}

const getters = {
    getIncomingInvoicesTable: state => state.incomingInvoicesTable,
    getLiabilitiesTable: state => state.liabilitiesTable,
    getXInvoicesTable: state => state.xInvoicesTable,
    getIncomingInvoice: state => state.incomingInvoice.incoming_invoice,
}

const actions = {
    fetchIncomingInvoices({commit, _}, params) {
        return commonTableFetch(commit, '/incoming-invoices', 'setIncomingInvoicesTable', params)
    },
    fetchLiabilities({commit, _}, params) {
        return commonTableFetch(commit, '/liabilities', 'setLiabilitiesTable', params)
    },
    fetchXInvoices({commit, _}, params) {
        return commonTableFetch(commit, '/x-invoices', 'setXInvoicesTable', params)
    },
    fetchIncomingInvoice({commit}, {id, type}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/incoming-invoices/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setIncomingInvoice', response.data)
                    resolve(response)
                })
        })
    },
    deleteIncomingInvoice({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/incoming-invoices/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    deleteIncomingInvoices({dispatch}, ids) {
        return new Promise((resolve) => {
            let queryString = ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');

            window.axios.delete(window.apiUrl + '/incoming-invoices?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },
    changeVerifiedIncomingInvoice({dispatch}, id) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/incoming-invoices/verify/' + id, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveIncomingInvoice({commit}, incomingInvoice) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/incoming-invoices', incomingInvoice, {
                'skip_loading': true
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    uploadXInvoice({commit}, insurance) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();

            Object.entries(insurance).forEach(([key, value]) => {
                formData.append(key, value);
            });

            window.axios.post(window.apiUrl + '/x-invoices/upload', formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setIncomingInvoicesTable(state, data) {
        state.incomingInvoicesTable = data
    },
    setLiabilitiesTable(state, data) {
        state.liabilitiesTable = data
    },
    setXInvoicesTable(state, data) {
        state.xInvoicesTable = data
    },
    setIncomingInvoice(state, data) {
        state.incomingInvoice = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}