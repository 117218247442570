import {commonStoreDelete, commonStoreFetch, commonStoreSave} from "@/store/common-functions";

function getDefaultState() {
    return {
        workflows: [],
        workflow: {},
        workflow_history: {},
        workflow_trigger: {},
        actions: [],
        customer_actions: [],
        todo_actions: [],
        position_actions: [],
        base_actions: [],
        conditions: [],
        attributes: [],
        detection_attributes: [],
        customer_attributes: [],
        invoice_attributes: [],
        time_types: [],
        time_attributes: [],
        time_directions: [],
        date_values: [],
        triggers: [],
    }
}

const getters = {
    getWorkflows: state => state.workflows,
    getWorkflow: state => state.workflow,
    getWorkflowHistory: state => state.workflow_history,
    getActions: state => {
        return state.actions.sort()
    },
    getCustomerActions: state => {
        return state.customer_actions.sort()
    },
    getTodoActions: state => {
        return state.todo_actions.sort()
    },
    getBaseActions: state => {
        return state.base_actions.sort()
    },
    getConditions: state => state.conditions,
    getAttributes: state => state.attributes,
    getDetectionAttributes: state => state.detection_attributes,
    getCustomerAttributes: state => state.customer_attributes,
    getInvoiceAttributes: state => state.invoice_attributes,
    getTodoAttributes: state => state.todo_attributes,
    getPositionAttributes: state => state.position_attributes,
    getDateValues: state => state.date_values,
    getTimeTypes: state => state.time_types,
    getTimeAttributes: state => state.time_attributes,
    getTimeDirections: state => state.time_directions,
    getTriggers: state => state.triggers,
    getTrigger: state => state.workflow_trigger,
}

const actions = {
    fetchWorkflows({commit}) {
        return commonStoreFetch(commit, '/workflow', 'setWorkflows')
    },
    fetchWorkflow({commit}, id) {
        return commonStoreFetch(commit, '/workflow/' + (id ? id : 0), 'setWorkflow')
    },
    fetchTrigger({commit}, id) {
        return commonStoreFetch(commit, '/workflow-trigger/' + (id ? id : 0), 'setTrigger')
    },
    fetchWorkflowHistory({commit}, hash) {
        return commonStoreFetch(commit, '/workflow-history/' + hash, 'setWorkflowHistory')
    },
    deleteWorkflow({dispatch}, id) {
        return commonStoreDelete('/workflow/' + id)
    },
    saveWorkflow({commit}, workflow) {
        return commonStoreSave('/workflow', workflow)
    },
    copyWorkflow({commit}, id) {
        return commonStoreSave('/workflow/copy/'+id, {})
    },
    saveTrigger({commit}, trigger) {
        return commonStoreSave('/workflow-trigger', trigger)
    },
    executeTime({commit}, time) {
        return commonStoreSave('/workflow-execute/' + time)
    },
    executeTrigger({commit}, data) {
        return commonStoreSave('/workflow-execute-trigger/' + data.id,data)
    },
    fetchOfferingSelection({commit}, data) {
        return commonStoreSave('/workflow-selection', data)
    },
}

const mutations = {
    setWorkflows(state, response) {
        state.workflows = response.workflows
    },
    setWorkflowHistory(state, response) {
        state.workflow_history = response.history
    },
    setTrigger(state, response) {
        state.workflow_trigger = response.trigger
    },
    setWorkflow(state, response) {
        state.workflow = response.workflow
        state.actions = response.actions
        state.base_actions = response.base_actions
        state.customer_actions = response.customer_actions
        state.todo_actions = response.todo_actions
        state.position_actions = response.position_actions
        state.conditions = response.conditions
        state.attributes = response.attributes
        state.detection_attributes = response.detection_attributes
        state.customer_attributes = response.customer_attributes
        state.invoice_attributes = response.invoice_attributes
        state.todo_attributes = response.todo_attributes
        state.position_attributes = response.position_attributes
        state.date_values = response.date_values
        state.time_attributes = response.time_attributes
        state.time_types = response.time_types
        state.time_directions = response.time_directions
        state.triggers = response.triggers
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}