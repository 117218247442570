<template>
    <b-card>
        <div class="timeline">
            <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                <div v-for="event in events"
                     :key="event.id"
                     v-if="showEvents.length ==0 || showEvents.includes(event.event)"
                     class="vertical-timeline-item vertical-timeline-element"
                >
                    <div>
                    <span class="vertical-timeline-element-icon bounce-in">
                        <font-awesome-icon :class="'badge badge-dot badge-dot-xl '+getClass(event)"
                                           :icon="getIcon(event)"/>
                    </span>
                        <div class="vertical-timeline-element-content bounce-in">
                            <template v-if="event.time">
                                <h4 class="timeline-title">{{ event.data.new_status || '-' }}</h4>
                                Status active time: {{ event.time }}
                            </template>
                            <template v-else>
                                <h4 class="timeline-title">
                                    <template v-if="event.event=='customer_todo'">
                                        <a href="#"
                                           @click="$root.$children[0].openModal('todo-modal',{id:event.data.id})"><b
                                            :class="event.data.status=='done' ? 'event-done' : ''"
                                            style="text-transform: uppercase;">{{ event.data.action }}</b></a>
                                        ({{ event.data.status }})
                                    </template>
                                    <template v-if="event.event=='customer_email_sent'">
                                        <a href="#"
                                           @click="$root.$children[0].openModal('show-email-message-modal', {messageId: event.data.id}, null, {width: '800px'})"><b
                                            style="text-transform: uppercase;">{{ event.event }}</b></a>
                                    </template>
                                    <template v-else><b style="text-transform: uppercase;">{{ event.event }}</b>
                                    </template>
                                    by <b>
                                    <template v-if="event.data & event.data.user_email">{{event.data.user_email}}</template>
                                    <template v-else>{{ (event.user ? event.user.email : 'System') }}</template>
                                </b>
                                    <font-awesome-icon
                                        v-if="event.data"
                                        @click="$root.$children[0].openModal('json-modal', {data: event.data})"
                                        size="sm"
                                        icon="eye"
                                        class="ml-1 text-info"
                                    />
                                </h4>
                            </template>

                            <template v-if="event.time"></template>
                            <div>
                                <div v-if="event.data && event.show_data">
                                    <template v-if="event.event == 'support_status'">
                                        User <b>{{ event.data.user_email }}</b> change status from
                                        <b>{{ event.data.old_status }}</b>
                                        to <b>{{ event.data.new_status }}</b>
                                    </template>
                                    <template v-else-if="event.event == 'support_due_date'">
                                        User <b>{{ event.data.user_email }}</b> change due date from
                                        <b>{{ event.data.old_due_date }}</b>
                                        to <b>{{ event.data.new_due_date }}</b>
                                    </template>
                                    <template v-else-if="event.event == 'support_domain'">
                                        User <b>{{ event.data.user_email }}</b> change domain from
                                        <b>{{ event.data.old_domain }}</b>
                                        to <b>{{ event.data.new_domain }}</b>
                                    </template>
                                    <template v-else-if="event.event == 'support_assign'">
                                        User <b>{{ event.data.user_email }}</b> change assign from
                                        <b>{{ event.data.old_assign }}</b>
                                        to <b>{{ event.data.new_assign }}</b>
                                    </template>
                                    <template v-else-if="event.event == 'support_create'">
                                        User <b>{{ event.data.user_email }}</b> create ticket
                                    </template>
                                    <template v-else-if="event.event == 'support_worklog'">
                                        New work log <b>{{ event.data.worklog_time }}</b> from
                                        <b>{{ event.data.worklog_author }}</b>
                                        to <b>{{ event.data.new_assign }}</b>
                                    </template>
                                    <template v-else-if="event.event == 'support_estimate_time'">
                                        Estimate time <b>{{ event.data.estimate_time }}</b> approve
                                    </template>
                                    <json-viewer v-else :value="event.data"></json-viewer>
                                </div>
                                <template v-else-if="event.event == 'status_changed'">
                                    Changed status from <b>{{ event.data.old_status || '-' }}</b> to
                                    <b>{{ event.data.new_status || '-' }}</b>
                                </template>
                                <template v-else-if="event.event == 'customer_status_changed'">
                                    <span v-if="event.data.status_id">Changed status from <b>{{
                                            getStatus('customer', event.data.status_id.before)
                                        }}</b> to <b>{{ getStatus('customer', event.data.status_id.after) }}</b></span>
                                </template>
                                <template v-else-if="event.event == 'payment_changed'">
                                    <span v-if="event.data.status_id">Changed status from <b>{{
                                            getStatus('payment', event.data.status_id.before)
                                        }}</b> to <b>{{ getStatus('payment', event.data.status_id.after) }}</b></span>
                                </template>
                                <template v-else-if="event.event == 'payment_method_changed'">
                                    <span v-if="event.data.payment_method">Changed payment method from <b>{{
                                            event.data.payment_method.before || '-'
                                        }}</b> to <b>{{ event.data.payment_method.after || '-' }}</b></span>
                                </template>
                                <template v-else-if="event.event == 'shipping_status_changed'">
                                    <span v-if="event.data.shipping_status">Changed shipping status from <b>{{
                                            event.data.shipping_status.before || '-'
                                        }}</b> to <b>{{ event.data.shipping_status.after || '-' }}</b></span>
                                </template>
                                <template v-else-if="event.event == 'fulfilment_status_changed'">
                                    <span v-if="event.data.fulfilment_status">Changed Fulfilment status from <b>{{
                                            event.data.fulfilment_status.before || '-'
                                        }}</b> to <b>{{ event.data.fulfilment_status.after || '-' }}</b></span>
                                </template>
                                <template v-else-if="event.event == 'offering_transaction_assigned'">
                                    <div>Name: <strong>{{ event.data.name }}</strong></div>
                                    <div>Amount: <strong>{{ event.data.amount }}&euro;</strong></div>
                                    <div>Date: {{ event.data.date_time | formatDate('DD.MM.YYYY HH:mm') }}</div>
                                    <div>{{ event.data.description }}</div>
                                </template>
                                <template v-else-if="event.event == 'offering_transaction_un_assigned'">
                                    <div>Name: <strong>{{ event.data.name }}</strong></div>
                                    <div>Amount: <strong>{{ event.data.amount }}&euro;</strong></div>
                                    <div>Date: {{ event.data.date_time | formatDate('DD.MM.YYYY HH:mm') }}</div>
                                    <div>{{ event.data.description }}</div>
                                </template>
                                <template v-else-if="event.event == 'document_created'">
                                    <div>Type: {{ event.data.doc_type }}</div>
                                    <div>Name: <a href="#"
                                                  @click="$root.openDocument(event.data.name)">{{ event.data.name }}</a>
                                    </div>
                                </template>
                                <template v-else-if="event.event == 'address_changed'">
                                    <div>Type: {{ event.data.type }}</div>
                                    <div>Fields: {{ Object.keys(event.data.changes).join(', ') }}</div>
                                </template>
                                <template v-else>
                                    <div v-if="event.data.title">{{ event.data.title }}</div>
                                    <div v-if="event.data.description" v-html="event.data.description"></div>
                                </template>
                            </div>
                            <span class="vertical-timeline-element-date"
                                  v-b-popover.hover.top="event.created_at"
                            >{{ event.created_at | formatDate('MMMM DD') }}</span>
                            <span class="vertical-timeline-element-time">{{
                                    event.created_at | formatDate('HH:mm')
                                }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'zw-events',
    props: {
        'events': {
            type: Array,
            default: [],
        },
        'show-events': {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {}
    },
    methods: {
        ...mapGetters('CommonData', ['getEntityStatuses']),
        getClass(event) {
            if (event.event.includes('created')) {
                return 'badge-success'
            }
            if (event.event.includes('updated')) {
                return 'badge-info'
            }
            if (event.event.includes('deleted')) {
                return 'badge-danger'
            }
            if (event.event.includes('synced')) {
                return 'badge-warning'
            }
            if (event.event == 'offering_transaction_assigned') {
                return 'badge-success'
            }
            if (event.event == 'offering_transaction_un_assigned') {
                return 'badge-danger'
            }
            return 'badge-light'
        },
        getIcon(event) {
            if (event.event.includes('created')) {
                return 'plus'
            }
            if (event.event.includes('updated')) {
                return 'edit'
            }
            if (event.event.includes('deleted')) {
                return 'trash'
            }
            if (event.event.includes('synced')) {
                return 'cloud-upload-alt'
            }
            if (event.event == 'offering_transaction_assigned') {
                return 'plus'
            }
            if (event.event == 'offering_transaction_un_assigned') {
                return 'minus'
            }
            return 'circle'
        },
        getStatus(type, id) {
            let status = this.getEntityStatuses()[type].find(status => status.id == id)
            if (status) {
                return status.name + ' ' + '(' + status.value + ')'
            } else {
                return '-'
            }
        }
    },
}
</script>
<style lang="scss">
.timeline {
    .event-done {
        text-decoration: line-through
    }

    .vertical-timeline {
        width: 100%;
        position: relative;
        padding: 1.5rem 0 1rem;
    }

    .vertical-timeline::before {
        content: '';
        position: absolute;
        top: 0;
        left: 130px;
        height: 100%;
        width: 4px;
        background: #e9ecef;
        border-radius: .25rem;
    }

    .vertical-timeline-element {
        position: relative;
        margin: 0 0 1rem;
    }

    .vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
        visibility: visible;
        animation: cd-bounce-1 .8s;
    }

    .vertical-timeline-element-icon {
        position: absolute;
        top: 0;
        left: 123px;
    }

    .vertical-timeline-element-icon .badge-dot-xl {
        box-shadow: 0 0 0 5px #fff;
    }

    .badge-dot-xl {
        width: 18px;
        height: 18px;
        position: relative;
    }

    .badge:empty {
        display: none;
    }


    .badge-dot-xl::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: .25rem;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -5px 0 0 -5px;
        background: #fff;
    }

    .vertical-timeline-element-content {
        position: relative;
        margin-left: 145px;
        font-size: .8rem;
    }

    .vertical-timeline-element-content .timeline-title {
        font-size: .8rem;
        margin: 0 0 .5rem;
        padding: 2px 0 0;

    }

    .vertical-timeline-element-content .vertical-timeline-element-date {
        display: block;
        position: absolute;
        left: -109px;
        top: 0;
        padding-right: 10px;
        text-align: right;
        font-weight: bold;
        color: #adb5bd;
        font-size: .7619rem;
        width: 92px;
        white-space: nowrap;
    }

    .vertical-timeline-element-content .vertical-timeline-element-time {
        display: block;
        position: absolute;
        left: -109px;
        top: 20px;
        width: 92px;
        padding-right: 10px;
        text-align: right;
        color: #adb5bd;
        font-size: .7619rem;
        white-space: nowrap;
    }

    .vertical-timeline-element-content:after {
        content: "";
        display: table;
        clear: both;
    }
}
</style>