import {commonStoreFetch, commonStoreSave, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        customer: {
            id: null,
            salutation: '',
            firstname: '',
            lastname: '',
            position: '',
            company: '',
            street: '',
            careOfAddress: '',
            additionalAddress: '',
            postCode: '',
            city: '',
            countryCode: 'DE',
            email: '',
            website: '',
            phone: '',
            fax: '',
            apiCustomerId: '',
            vatId: '',
            vatNumber: '',
            vat: '',
            groups: [],
            notes: '',
            facebook: '',
            linkedIn: '',
            xing: '',
        },
        customersTable: {},
        customerDocumentsTable: {},
        customerClientsTable: {},
        document: {},
        template: {},
        events: [],
        events_customer_data: [],
        types: [],
        type: {},
    }
}

const getters = {
    getCustomer: state => state.customer,
    getCustomersTable: state => state.customersTable,
    getCustomerDocumentsTable: state => state.customerDocumentsTable,
    getCustomerClientsTable: state => state.customerClientsTable,
    getDocument: state => state.document,
    getEvents: state => state.events,
    getEventsCustomerData: state => state.events_customer_data,
    getTypes: state => state.types,
    getType: state => state.type,
    getTemplate: state => state.template,
}

const actions = {
    fetchCustomer({commit}, id) {
        commit('resetCustomer')

        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/customer/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setCustomer', {...response.data, id})
                    resolve(response)
                })
        })
    },
    fetchNextCustomer({commit}, id) {
        commit('resetCustomer')

        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/customer/' + (id ? id : 0) + '?next=1', {'skip_loading': true})
                .then(response => {
                    commit('setCustomer', {...response.data, id})
                    resolve(response)
                })
        })
    },
    saveCustomer({commit}, customer) {
        return new Promise((resolve, reject) => {
            if (customer.id) {
                window.axios.put(window.apiUrl + '/customer/' + customer.id, customer, {'skip_loading': true})
                    .then(response => {
                        commit('setCustomer', response.data)
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error.response.data)
                    })
            } else {
                window.axios.post(window.apiUrl + '/customer', customer)
                    .then(response => {
                        commit('setCustomer', response.data)
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error.response.data)
                    })
            }
        })
    },
    saveType({commit}, type) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/customer/type/' + (type.id ? type.id : 0), type, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error.response.data)
                })
        })
    },
    saveTemplate({commit}, template) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/customer/template/' + (template.id ? template.id : 0), template, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error.response.data)
                })
        })
    },
    savePassword({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/customer/new-password/' + data.id, data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error.response.data)
                })
        })
    },
    deleteCustomer({dispatch}, {customerId}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/customer/' + customerId).then((response) => {
                resolve(response);
            })
        })
    },
    deleteType({dispatch}, {id}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/customer/type/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    deleteCustomers({dispatch}, ids) {
        return new Promise((resolve) => {
            let queryString = ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');

            window.axios.delete(window.apiUrl + '/customer?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },
    fetchCustomers({commit, _}, params) {
        return commonTableFetch(commit, '/customer', 'setCustomersTable', params)
    },
    fetchDocumentPreview({commit, _}, params) {
        return commonStoreSave('/customer-documents/preview', params, true)
    },
    fetchDocument({commit, _}, id) {
        return commonStoreFetch(commit, '/customer-documents/' + id, 'setDocument', true)
    },
    fetchCustomerTemplate({commit, _}, id) {
        return commonStoreFetch(commit, '/customer/template/' + (id ? id : 0), 'setTemplate', true)
    },
    fetchEvents({commit, _}, id) {
        commit('resetEvents')
        return commonStoreFetch(commit, '/customer/events/' + id, 'setEvents', false)
    },
    fetchTypes({commit, _}) {
        return commonStoreFetch(commit, '/customer/types', 'setTypes', false)
    },
    fetchType({commit, _}, id) {
        return commonStoreFetch(commit, '/customer/type/' + id, 'setType', false)
    },
    saveDocument({commit, _}, params) {
        return commonStoreSave('/customer-documents/save', params, true)
    },
    assignClientToProvider({commit, _}, params) {
        return commonStoreSave('/customer-client-assign', params, true)
    },
    unlinkClientFromProvider({commit, _}, params) {
        return commonStoreSave('/customer-client-unlink', params, true)
    },
    fetchCustomerDocuments({commit, _}, params) {
        return commonTableFetch(commit, '/customer-documents', 'setCustomerDocuments', params)
    },
    fetchCustomerClients({commit, _}, params) {
        return commonTableFetch(commit, '/customer-clients', 'setCustomerClients', params)
    },
    deleteDocument({dispatch}, {documentId}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/customer-documents/' + documentId).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    resetEvents(state) {
        state.events = []
    },
    resetCustomer(state) {
        state.customer = {}
    },
    setCustomer(state, customer) {
        state.customer = {...getDefaultState().customer, ...customer}
    },
    setCustomerDocuments(state, data) {
        state.customerDocumentsTable = data
    },
    setCustomerClients(state, data) {
        state.customerClientsTable = data
    },
    setDocument(state, data) {
        state.document = data.document
    },
    setTemplate(state, data) {
        state.template = data.template
    },
    setEvents(state, data) {
        state.events = data.events
        state.events_customer_data = data.customer_data
    },
    setTypes(state, data) {
        state.types = data.types
    },
    setType(state, data) {
        state.type = data.type
    },
    setCustomersTable(state, data) {
        state.customersTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}